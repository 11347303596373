import React from 'react'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import styles from "../../styles/info-site/CaseStudySecretSales.module.css";

// import ninjaKitchenImg from "../../assets/ninjaKitchen-deodorants.jpeg"
import ninjaKitchenImg from "../../assets/ninja-1.webp"
import ninjaKitchen2Img from "../../assets/ninja-2.webp"
import tyvisoLogo from "../../assets/simple-logo.png"

// import TestimonoalImgOne from "../../assets/ninja-3.webp"
import TestimonoalImgTwo from "../../assets/adrian.jpg"

const testimonials = [
  // {
  //   id: 'ninjaKitchen',
  //   img: TestimonoalImgOne,
  //   alt: 'Alex Adamson, CMO, SecretSales',
  //   text: '"Working with Tyviso and their proprietary technology to be able to help bring affinity third party offers to our customers has been instrumental in both improving our performance and customer proposition over the last 6 months. \n\n It\'s important that when we identify secondary revenue streams for the business that we do so in a way which is seamless or helps improve our overall customer experience and proposition, which Tyviso unlocks for us."',
  //   author: 'Alex Adamson, CMO, SecretSales'
  // },
  {
    id: 'tyviso',
    img: TestimonoalImgTwo,
    alt: 'Adrian Vella, CEO, Tyviso',
    text: '"This campaign perfectly shows that brand partnerships are more than a flash-in-pan campaign.\n\nFrom the increased basket completion and higher spending, this campaign has added significant measurable value to the customer purchasing experience."',
    author: 'Adrian Vella, CEO, Tyviso'
  }
]

const resultList = [
  {
    pre: 'Average order value increased by  ',
    bold: '14 %'
  },
  {
    pre: 'Basket uplift by  ',
    bold: '59.02%'
  },
  {
    bold: '13% ',
    normal: ' offer conversion rate',
  },
  {
    bold: '£1m+ ',
    normal: ' overall CRO benefit',
  }
]

function CaseStudyNinjaKitchen({ title, subTitle }) {
  return (
    <div className={styles.CaseStudy}>

      <div className={styles.CaseStudyFeaturedWrapper}>
        <img
          className={styles.CaseStudyFeaturedImg}
          src={ninjaKitchenImg}
          alt="ninjakitchen"
        />
        <div className={styles.CaseStudyFeaturedContent}>
          <h2 className={styles.CaseStudySubTitle}>
            <FormattedHTMLMessage id={title} />
          </h2>
          <p className={styles.CaseStudyText}>
            <FormattedMessage id={subTitle} />
          </p>
        </div>
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedMessage id="ninjaKitchenCaseObjectiveTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="ninjaKitchenCaseObjectiveDescription" />
          </p>
          <ol>
            <li>
              <FormattedMessage id="ninjaKitchenCaseObjectiveItemOne" />
            </li>
            <li>
              <FormattedMessage id="ninjaKitchenCaseObjectiveItemTwo" />
            </li>
            <li>
              <FormattedMessage id="ninjaKitchenCaseObjectiveItemThree" />
            </li>
          </ol>
        </div>
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedMessage id="ninjaKitchenCaseApproachTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="ninjaKitchenCaseApproachItemOne" />
          </p>
          <p>
            <FormattedMessage id="ninjaKitchenCaseApproachItemTwo" />
          </p>
          <p>
            <FormattedMessage id="ninjaKitchenCaseApproachItemThree" />
          </p>
        </div>
      </div>

      <div className={styles.CaseStudySimply}>
        <img src={ninjaKitchen2Img} alt="" />
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3 className={styles.CaseStudyDescColResult}>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedHTMLMessage id="ninjaKitchenCaseResultsTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="ninjaKitchenCaseResultsItemOne" />
          </p>
          <ul>
            {
              resultList.map(({pre, bold, normal}, idx) => (
                <li key={`result-list--${idx}`}>
                  {pre}{bold ? <strong>{bold}</strong> : null}{normal}
                </li>
              ))
            }
          </ul>
        </div>
      </div>

      <div className={styles.CaseStudyTestimonialWrapper}>
        {
          testimonials.map(item => (
            <div className={styles.CaseStudyTestimonial}>
              <div className={styles.CaseStudyTestimonialImage}>
                <img
                  src={item.img}
                  alt={item.alt}
                />
              </div>
              <div className={styles.CaseStudyTestimonialContent}>
                <p>{item.text}</p>
                <h3>{item.author}</h3>
              </div>
          </div>
          ))
        }
      </div>
    </div>
  );
};

export default CaseStudyNinjaKitchen;